<template>
    <!-- <div class="row">
        <label for="customer_id" class="col-sm-4 col-form-label required-label">{{$t('navigation.customers')}}</label>
        <div class="col-sm-8">
            <select v-model="company.customer_id" id="customer_id">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
            </select>
        </div>
    </div> -->
    <div class="row">
        <label for="name" class="col-sm-4 col-form-label required-label">{{$t('overview.name')}}</label>
        <div class="col-sm-8">
            <input v-model="company.name" type="text" id="name" class="col-sm-8" />
        </div>
    </div>
    <div class="row">
        <label for="number" class="col-sm-4 col-form-label required-label">{{$t('overview.number')}}</label>
        <div class="col-sm-8">
            <input v-model="company.number" type="text" id="number" />
        </div>
    </div>
    <!--<div class="row">
        <label for="address" class="col-sm-4 col-form-label required-label">{{$t('form.address')}}</label>
        <div class="col-sm-8">
            <textarea v-model="company.address" id="address"></textarea>
        </div>
    </div>-->
    <div class="row">
        <label for="company_director_email" class="col-sm-4 col-form-label">{{$t('overview.logo')}}</label>
        <div class="col-sm-8">
            <input type="file" accept="image/*" @change="uploadFile($event)" class="optional" />
            <div v-if="company.logo" :style="{backgroundImage: 'url(' + company.logo.url + ')'}" class="uploaded-image"></div>
            <div v-else-if="company.logo_file" :style="{backgroundImage: 'url(' + company.logo_file.data + ')'}" class="uploaded-image"></div>
        </div>
    </div>
    <br>
    <div class="row">
        <label for="representative" class="col-sm-4 col-form-label required-label">{{$t('company.representative')}}</label>
        <div class="col-sm-8">
            <input v-model="company.representative" type="text" id="representative" />
        </div>
    </div>
    <div class="row">
        <label for="representative_email" class="col-sm-4 col-form-label required-label">{{$t('form.email')}}</label>
        <div class="col-sm-8">
            <input v-model="company.representative_email" type="email" id="representative_email" />
        </div>
    </div>
    <br>
    <div class="row">
        <label for="company_director" class="col-sm-4 col-form-label">{{$t('company.company_director')}}</label>
        <div class="col-sm-8">
            <input v-model="company.company_director" type="text" id="company_director" class="optional" />
        </div>
    </div>
    <div class="row">
        <label for="company_director_email" class="col-sm-4 col-form-label">{{$t('form.email')}}</label>
        <div class="col-sm-8">
            <input v-model="company.company_director_email" type="email" id="company_director_email" class="optional" />
        </div>
    </div>
    <br>
    <div class="row">
        <label for="api_username" class="col-sm-4 col-form-label">Single Sign-On:</label>
    </div>
    <div class="row">
        <label for="api_username" class="col-sm-4 col-form-label">{{$t('form.api_username')}}</label>
        <div class="col-sm-8">
            <input v-model="company.api_username" type="email" id="api_username" class="optional" />
        </div>
    </div>
    <br>
    <div class="row">
        <label for="api_token" class="col-sm-4 col-form-label">{{$t('form.api_token')}}</label>
        <div class="col-sm-8">
            <input v-model="company.api_token" type="email" id="api_token" class="optional" />
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-6">
            <label for="select-template">{{$tc('navigation.templates', 2)}}</label>
            <div class="w-100">
                <select @change="addTemplate($event, 'templates')" id="select-template">
                    <option value=''>{{$t('form.select')}}</option>
                    <option v-for="(template, index) in templates" :key="template.name" :value="index" :disabled="checkIfTemplateAlreadySelected(template)">
                        {{template.name}}
                    </option>
                </select>
            </div>
            <div class="d-flex flex-column flex-wrap stype-column w-100">
                <div v-for="template in company.templates.filter(template => !template.is_appendage)" :key="template.name" class="w-auto">
                    <button type="button" class="btn btn-sm text-danger p-0" title="Klik om te verwijderen" @click="deleteFromList(template.id, 'templates')">
                        <i class="far fa-close"></i>
                    </button>
                    &ensp;
                    <span> {{ template.name }} </span>
                </div> 
            </div>
        </div>
        <div class="col-6">
            <label for="select-appendix">{{$tc('navigation.attachments', 2)}}</label>
            <div class="w-100">
                <select @change="addTemplate($event, 'attachments')" id="select-appendix">
                    <option value=''>{{$t('form.select')}}</option>
                    <option v-for="(template, index) in attachments" :key="template.name" :value="index" :disabled="checkIfTemplateAlreadySelected(template)">
                        {{template.name}}
                    </option>
                </select>
            </div>
            <div class="d-flex flex-column flex-wrap stype-column w-100">
                <div v-for="template in company.templates.filter(template => template.is_appendage)" :key="template.name" class="w-auto">
                    <button type="button" class="btn btn-sm text-danger p-0" title="Klik om te verwijderen" @click="deleteFromList(template.id, 'templates')">
                        <i class="far fa-close"></i>
                    </button>
                    &ensp;
                    <span> {{ template.name }} </span>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
    import customerService from '@/services/DebtorService.js';
    import templateService from '@/services/TemplateService';
    import moment from 'moment'
    export default {
        name: 'CompanyForm',
        props: ['itemData'],
        data() {
            return {
                company: {
                    "name": "",
                    "number": "",
                    "representative": "",
                    "representative_email": "",
                    "company_director": "",
                    "company_director_email": "",
                    "address": "",
                    "customer_id": null,
                    "templates": [],
                    "logo": null
                },
                submitted: false,
                loading: false,
                invalid: {},
                attachments: [],
                templates: [],
                customers: []
            }
        },
        watch: {
            itemData: function (val) {
                this.company = val    
            },
        },
        methods: {
            validate(){
                let company = this.company;
                const required = document.querySelectorAll('#formModal input:not(.optional), #customer_id, textarea');
                let isValid = true;
                required.forEach(field => {
                    if(!field.value || field.value == this.$t('form.select')){
                        isValid = false;
                        field.classList.add('is-invalid');
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                });
                if(!isValid){
                    alert(this.$t('errors.empty_fields'));
                }
                return isValid;
            },
            getData() {
                return this.company;
            },
            setData(data) {
                this.company = data
            },
            reset(shouldLoadData = true) {
                this.company = {
                    "name": "",
                    "number": "",
                    "representative": "",
                    "representative_email": "",
                    "company_director": "",
                    "company_director_email": "",
                    "address": "",
                    "customer_id": null,
                    "templates": [],
                    "logo": null
                }
                if(shouldLoadData && this.templates.length == 0 && this.customers.length == 0){
                    this.getTemplates();
                    this.getDebtors();
                }
                this.loading = false
                this.invalid = {}
            },
            addTemplate(e, list){
                if(e.target.value){
                    const index = (parseInt(e.target.value) + 1) - 1;
                    this.company.templates.push(this[list][index]);
                }
                e.target.value = '';
            },
            deleteFromList(id, list){
                this.company[list].splice(this.company.templates.findIndex(template => template.id == id), 1);
            },
            getTemplates(){
                templateService.index().then(response => {
                    this.attachments = response.data.filter(template => template.is_appendage);
                    this.templates = response.data.filter(template => !template.is_appendage);
                }).catch(error => {
                    console.log('error', error)
                })
            },
            getDebtors(){
                customerService.index().then(response => {
                    this.customers = response.data;
                }).catch(error => {
                    console.log('error', error)
                })
            },
            checkIfTemplateAlreadySelected(item){
                return this.company.templates.findIndex(template => template.id == item.id) >= 0;
            },

            async uploadFile(e){
                e.preventDefault();
                if (e.target.files) {
                    let file = e.target.files[0];

                    let fileSize = Math.ceil(file.size / 1024) + ' kb';
                    let fileType = file.type.split('/')[1];
                    if(file.name.match(/\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/)){
                        this.company.logo = {
                            'type': fileType,
                            'name': file.name.replace(/[^a-z0-9.]/gi, '_'),
                            'size': fileSize,
                            'date': moment(),
                            'blob': await this.blobToBase64(file),
                            'url': window.URL.createObjectURL(file)
                        };
                    }
                }
            },
            async blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
                    reader.readAsDataURL(blob);
                });
            }
        },
        mounted () {
            this.reset(false)
        }
    }
</script>
